import { createName } from "../../utils";
const label = "数据源";
const name = "yes-dataSource";

export default {
    icon: "yes-icon-dataSource",
    label,
    name,
    rule() {
        return {
            name: "",
            type: name,
            children: [],
            props: {
                type: "none",
                initData: {},
                firstLoad: true,
                initSize: "9999",
            },
        };
    },
    props() {
        return [
            createName(),
            // { type: 'input', field: 'sourceName', title: '数据源名称' },
            {
                type: "select",
                field: "type",
                title: "类型",
                options: [
                    { label: "视图", value: "view" },
                    { label: "模型(单行)", value: "model" },
                    { label: "模型(多行)", value: "moreModel" },
                    { label: "接口(自定义/app)", value: "app" },
                    { label: "接口(原生/api)", value: "api" },
                    { label: "配置", value: "config" },
                    { label: "空", value: "none" },
                ],
                control: [
                    {
                        value: "view",
                        rule: [
                            { type: "input", field: "appCode", title: "应用编号" },
                            { type: "input", field: "viewCode", title: "视图编号" },
                            { type: "input", field: "initSize", title: "默认条数" },
                            {
                                type: "yesStruct",
                                field: "initData",
                                title: "默认参数",
                                props: {
                                    type: "textarea",
                                    rows: 8,
                                },
                            },
                            {
                                type: "select",
                                field: "firstLoad",
                                title: "默认加载",
                                value: true,
                                options: [
                                    { label: "是", value: true },
                                    { label: "否", value: false },
                                ],
                            },
                        ],
                    },
                    {
                        value: "moreModel",
                        rule: [
                            { type: "input", field: "appCode", title: "应用编号" },
                            { type: "input", field: "viewCode", title: "模型编号" },
                            { type: "input", field: "initSize", title: "默认条数" },
                            {
                                type: "yesStruct",
                                field: "initData",
                                title: "默认参数",
                                props: {
                                    type: "textarea",
                                    rows: 8,
                                },
                            },
                            {
                                type: "select",
                                field: "firstLoad",
                                title: "默认加载",
                                value: true,
                                options: [
                                    { label: "是", value: true },
                                    { label: "否", value: false },
                                ],
                            },
                        ],
                    },
                    {
                        value: "model",
                        rule: [
                            { type: "input", field: "appCode", title: "应用编号" },
                            { type: "input", field: "model", title: "模型编号" },
                            {
                                type: "yesStruct",
                                field: "initData",
                                title: "默认参数",
                                props: {
                                    type: "textarea",
                                    rows: 8,
                                },
                            },
                            {
                                type: "select",
                                field: "firstLoad",
                                title: "默认加载",
                                value: true,
                                options: [
                                    { label: "是", value: true },
                                    { label: "否", value: false },
                                ],
                            },
                        ],
                    },
                    {
                        value: "app",
                        rule: [
                            { type: "input", field: "appCode", title: "应用编号" },
                            { type: "input", field: "apiCode", title: "自定义接口编号" },
                            {
                                type: "yesStruct",
                                field: "initData",
                                title: "默认参数",
                                props: {
                                    type: "textarea",
                                    rows: 8,
                                },
                            },
                            {
                                type: "select",
                                field: "firstLoad",
                                title: "默认加载",
                                value: true,
                                options: [
                                    { label: "是", value: true },
                                    { label: "否", value: false },
                                ],
                            },
                        ],
                    },
                    {
                        value: "api",
                        rule: [
                            { type: "input", field: "appCode", title: "应用编号" },
                            { type: "input", field: "model", title: "模型编号" },
                            { type: "input", field: "apiCode", title: "接口编号" },
                            // {
                            //   type: "select",
                            //   field: "apiType",
                            //   title: "自定义接口类型",
                            //   options: [
                            //     { label: "GET", value: "GET" },
                            //     { label: "POST", value: "POST" },
                            //     { label: "DELETE", value: "DELETE" },
                            //   ],
                            // },
                            {
                                type: "yesStruct",
                                field: "initData",
                                title: "默认参数",
                                props: {
                                    type: "textarea",
                                    rows: 8,
                                },
                            },
                            {
                                type: "select",
                                field: "firstLoad",
                                title: "默认加载",
                                value: true,
                                options: [
                                    { label: "是", value: true },
                                    { label: "否", value: false },
                                ],
                            },
                        ],
                    },
                    {
                        value: "config",
                        rule: [
                            { type: "input", field: "appCode", title: "配置编号" },
                            { type: "input", field: "configUrl", title: "配置路径" },
                            {
                                type: "select",
                                field: "firstLoad",
                                title: "默认加载",
                                value: true,
                                options: [
                                    { label: "是", value: true },
                                    { label: "否", value: false },
                                ],
                            },
                        ],
                    },
                    {
                        value: "none",
                        rule: [
                            {
                                type: "yesStruct",
                                field: "initDataValue",
                                title: "默认值",
                                props: {
                                    type: "textarea",
                                    rows: 8,
                                },
                            },
                        ],
                    },
                ],
            },
            {
                type: "yesStruct",
                field: "beforeLoadEvent",
                title: "加载前脚本(参数:vm=this)",
                props: {
                    valueType: "function",
                },
            },
            {
                type: "yesStruct",
                field: "afterLoadEvent",
                title: "加载后脚本(参数:vm=this)",
                props: {
                    valueType: "function",
                },
            },
        ];
    },
};
